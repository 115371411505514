export const creativeGroups = {
  // системные группы
  CROP: "crop",
  HD: "hd",

  // отображаемые группы
  DISNEY: "disney",
  DISNEY_WITH_5273: "disney_with_5273",
  DISNEY_COLLAGE: "disney_collage",
  DISNEY_2D: "disney_2d",
  VECTOR: "vector",
  ARCHER: "archer",
  SIMPSONS: "simpsons",
  CARICATURE: "caricature",
  CARICATURE_2: "caricature_2",
  CARICATURE_3: "caricature_3",
  T4532: "t4532",
  CARLAFUENTESART: "carlafuentesart",
  BARBIE: "barbie",
  CARTOON: "cartoon",
  CARTOON2: "cartoon2",
  VECTORPSP: "vectorpsp",
  CARTOON_ANIM: "cartoon_anim",
  COLLAGE: "collage",
  CUPID21_1: "cupid21_1",
  CUPID21_2: "cupid21_2",
  POPART: "popart",
  SCARYDOLLS: "scarydolls",
  KOMUK3111: "komuk3111",
  DISNEY_5276: "disney5276",
  DISNEY_5277: "disney5277",
  DISNEY_5336: "disney5336",
  BARBIE_5278: "barbie5278",
  BARBIE_5279: "barbie5279",
  CARTOON_VECTOR_BODY: "cartoon_vector_body",
  VOILA: "voila",
  TAB_210622: "tab210622",
  TAB_210623: "tab210623",
  DISNEY2DMIX: "disney2dmix",
  DISNEY_5357: "disney5357",
  DISNEY_5361: "disney5361",
};

export const sitesGroups = {
  "classic": [
    creativeGroups.CROP,
    creativeGroups.DISNEY_COLLAGE,
    creativeGroups.TAB_210622,
    creativeGroups.DISNEY_5277,
    creativeGroups.T4532,
    creativeGroups.DISNEY2DMIX,
    creativeGroups.TAB_210623,
    creativeGroups.DISNEY_5361,
  ],
  "classic_china": [
    creativeGroups.CROP,
    creativeGroups.DISNEY_COLLAGE,
    creativeGroups.TAB_210622,
    creativeGroups.DISNEY_5277,
    creativeGroups.T4532,
    creativeGroups.DISNEY2DMIX,
    creativeGroups.TAB_210623,
    creativeGroups.DISNEY_5361,
    creativeGroups.CARTOON,
    creativeGroups.CARTOON2,
  ],
  "labs": [
    creativeGroups.CROP,
    creativeGroups.CARTOON_ANIM,
    creativeGroups.DISNEY,
    creativeGroups.DISNEY_WITH_5273,
    creativeGroups.DISNEY_2D,
    creativeGroups.VECTOR,
    creativeGroups.SIMPSONS,
    creativeGroups.ARCHER,
    creativeGroups.CARICATURE_2,
    creativeGroups.CARICATURE,
    creativeGroups.BARBIE,
    creativeGroups.CARTOON,
    creativeGroups.POPART,
    creativeGroups.CARLAFUENTESART,
  ],
  "love": [
    creativeGroups.CROP,
    creativeGroups.CUPID21_1,
    creativeGroups.CUPID21_2,
  ],
};

export const collageGroups = {
  t4532_disney5277_barbie5279_vectorpsp: "t4532_disney5277_barbie5279_vectorpsp",
};

export const collageGroupCreatives = {
  [collageGroups.t4532_disney5277_barbie5279_vectorpsp]: [
    creativeGroups.T4532,
    creativeGroups.DISNEY_5277,
    creativeGroups.BARBIE_5279,
    creativeGroups.VECTORPSP,
  ],
};